/* logo  */


/* header */
.header {
    //background: $white;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px 0;
    z-index: 220;
    @media screen and (prefers-color-scheme: dark) {
        //background: $black;
        background: transparent;

    }
    @include media-breakpoint-up(lg) {
        position: fixed;
    }
    &.shrink {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1030;
    }
    a {
       
        &:hover, &:focus {
            text-decoration: none;
            
        }
    }

    .menu {
        padding: 0;

        @include media-breakpoint-up(lg) {
            margin: 0 0 0 auto;
        }


        >li {
            padding: 0;
            margin-left: .9rem;
            line-height: 1;
            @include media-breakpoint-up(lg) {
                margin-left: .9rem;
            }
            >a {
            }
            &.current-menu-item {
                a {
                    text-decoration: underline;
                }
            }
            &:last-child {
                padding-right: 0 !important;
            }
           
        }
    }
 

}


.menu-principal {
    font-family: $font-title;
    font-size: 2rem;
    @include media-breakpoint-up(lg) {
        font-size: 3rem;
    }
}

.full-menu {
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
    background-color: $white;
    opacity: 0.6;
    //z-index: 800;
    transform: translateY(-200%);
    transition: all 400ms ease-in;
    top: 0;
    z-index: 200;
    width: 100%;
    height: 100vh;
    @media screen and (prefers-color-scheme: dark) {
        background-color: $black;
    }
}

.full-menu-content {
    margin: auto 0 0 0;
    padding: 100px 15px;
}

.full-menu ul {
    list-style: none;
    margin: auto 0;
    padding: 0;
}


.full-menu li {
    
    color: $black;
    opacity: 0;
    cursor: pointer;
    text-align: left;
    transform: translateY(-100%);
    transition: all 500ms ease-in;
    
    @media screen and (prefers-color-scheme: dark) {
        color: $white;
    }

    a {
        color: $black;
        padding: 0;
        display: block;
        text-align: center;
        @media screen and (prefers-color-scheme: dark) {
            color: $white;
        }
    }
}


.full-menu.active {
    transform: translateY(0%);
    opacity: 1;
    top: 0;
    //z-index: 900;
    //padding-top: 50px;
    //top: 0px;
    @include media-breakpoint-up(md) {
        //top: 0px;
        //padding-top: 80px;
    }
}

.full-menu.active li {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
}




.full-menu.active li:hover {
    color: #000;
}

.full-menu.active .cat a:hover {
    color: #000;
}


.full-menu.active .m1 {
    transition-delay: 0.0s;
}

.full-menu.active .m2 {
    transition-delay: 0.0s;
}

.full-menu.active .m3 {
    transition-delay: 0.0s;
}

.full-menu.active .m4 {
    transition-delay: 0.0s;
}





.lang {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    font-weight: lighter;
    //font-size: 80%;
  
    li {
        display: flex;
        align-items: center;
        padding-right: 0.2rem;

        line-height: 1.1;
        //text-transform: uppercase;
     
        &.active {
            
            &:before {
                content: '/';
                padding-right: 0.2rem;
            }
            a {
                border-bottom: 1px solid #000;
                @media screen and (prefers-color-scheme: dark) {
                    border-bottom: 1px solid #fff;
                }
            }

        }
       
           
            &:before {
                content: '/';
                padding-right: 0.2rem;
            }
            &:first-child {
                &:before {
                    content: '' !important;
                    //padding-right: 0;
                }
            }
            &:last-child {
                padding-right: 0;
            }

        
        
        a {
            display: block;
            position: relative;
            &:hover, &:focus {
                text-decoration: none;
            }
        }
   
 
    }
}



